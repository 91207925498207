import request from '@/utils/request'

/**
 * 公共 企业钱包基本信息
 * @returns {*}
 */
export function firm_wallet (params) {
    return request({
        url: `platform/facilitator/firm_wallet`,
        method: 'get',
        params
    })
}
/**
 * 公共 企业钱包收支明细
 * @returns {*}
 */
export function balance_payments (params) {
    return request({
        url: `platform/facilitator/balance_payments`,
        method: 'get',
        params
    })
}

/**
 * 公共 企业钱包开票记录接口
 * @returns {*}
 */
export function invoice_log (params) {
    return request({
        url: `platform/facilitator/invoice_log`,
        method: 'get',
        params
    })
}
/**
 * 公共 企业钱包提现记录接口
 * @returns {*}
 */
export function extract_log (params) {
    return request({
        url: `platform/facilitator/extract_log`,
        method: 'get',
        params
    })
}
